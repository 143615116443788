import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import Button from '../components/button'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Text, { typography } from '../components/text'
import { global } from '../styles/global'
import { format } from '../utils/date'

const ArticleTemplate = ({ data: { article, settings } }) => {
  return (
    <Layout pageTags={article.seoMetaTags} noIndex={article.noIndex}>
      <div>
        <header
          css={[
            tw`pt-8 pb-20 bg-secondary-500 bg-opacity-5 lg:py-32`,
            article.image && tw`pb-36 lg:pb-64`,
          ]}
        >
          <div css={[global`layout.container`, global`layout.grid`]}>
            <div css={tw`mt-20 col-span-4 md:col-span-8 lg:(col-span-2 mt-0) xl:col-span-3`}>
              <Button
                type="primary-reverse"
                size="sm"
                label={settings.backLabel}
                link={settings.backLink}
                theme="transparent-transparent"
                style={css`
                  ${tw`mb-10 lg:(-mt-3 mb-0)`}
                  span {
                    ${tw`m-0`}
                  }
                  svg {
                    ${tw`mr-3`}
                  }
                `}
              />
            </div>
            <div
              css={tw`flex flex-col items-start col-span-4 pl-6 md:col-span-8 lg:(pl-0 col-span-8) xl:col-span-10`}
            >
              <div
                css={[
                  global`typography.subtitle`,
                  tw`block mb-10 font-bold capitalize text-primary-500 text-opacity-47 lg:mb-16`,
                ]}
              >
                <time>{format(article.date, 'dd MMMM yyyy')}</time>
              </div>
              <Heading
                content={article.headline}
                headingType="h1"
                style={tw`font-bold text-primary-500`}
              />
              {article.subheading && (
                <Heading content={article.subheading} headingType="h5" style={tw`mt-6`} />
              )}
            </div>
          </div>
        </header>
        <section
          css={[global`layout.container`, global`layout.grid`, tw`pt-20 pb-24 lg:(pt-32 pb-40)`]}
        >
          {article.image && (
            <div
              css={tw`col-span-4 md:col-span-8 -mt-36 mb-16 -ml-container-mobile lg:(-mt-64 mb-32) xl:(col-start-4 col-span-10)`}
            >
              <Image image={article.image} />
            </div>
          )}
          <Text
            content={article.contentNode}
            style={[
              typography,
              global`typography.body-md`,
              tw`col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-14)`,
            ]}
          />
        </section>
      </div>
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  }),
}

export default ArticleTemplate

export const query = graphql`
  query ($slug: String!) {
    article: datoCmsPressRelease(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      headline
      subheading
      date
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    settings: datoCmsParentNewsInsight {
      backLabel
      backLink
    }
  }
`
